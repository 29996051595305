import React, { PropsWithChildren, useContext, useState } from 'react'

type State = {
  isVisible: boolean
}

type Methods = {
  setIsVisible: (boolean) => void
  close: () => void
  open: () => void
}

const Context = React.createContext<State & Methods>({
  isVisible: false,
  setIsVisible: () => null,
  open: () => null,
  close: () => null,
})

export const GetInTouchContextProvider = ({ children }: PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(false)
  const open = () => setIsVisible(true)
  const close = () => setIsVisible(false)
  return (
    <Context.Provider value={{ isVisible, setIsVisible, open, close }}>{children}</Context.Provider>
  )
}

export const useGetInTouchContext = () => useContext(Context)
