import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const SharpnessCorrection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <circle cx="28" cy="28" r="24" stroke="currentColor" strokeWidth="2"></circle>
        <path stroke="currentColor" strokeWidth="2" d="M28 18l11 17H17l11-17z"></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <circle cx="24" cy="24" r="20" stroke="currentColor" strokeWidth="2"></circle>
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M24 15.666l9.167 14.167H14.833L24 15.667z"
        ></path>
      </svg>
    </>
  )
}
