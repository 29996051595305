import React, { FC } from 'react'

export const CommandLine: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 21l2.5 2.5L17 26m4 0h5.5M16.1 38c-1.712 0-3.1-1.343-3.1-3V20c0-1.657 1.388-3 3.1-3h24.8c1.712 0 3.1 1.343 3.1 3v15c0 1.657-1.388 3-3.1 3H16.1z"
      ></path>
    </svg>
  )
}
