import React, { FC } from 'react'

export const Application: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.7 34.667c-1.491 0-2.7-1.194-2.7-2.667V18.667C15 17.194 16.209 16 17.7 16h21.6c1.491 0 2.7 1.194 2.7 2.667V32c0 1.473-1.209 2.667-2.7 2.667H17.7zM23.1 40h10.8M28.5 34.666V40"
      ></path>
    </svg>
  )
}
