import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const NoiseReduction: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M28 4L4 28l6 6 6 6 6 6 6 6m0-48l24 24-6 6-6 6-6 6-6 6m0-48v48M14.5 34h2.25m6.75 0h-2.25m-1.5 6h1.5m4.25 0H24m-2-12h-3.5m-9 0H13m1.5-6h2.25m6.75 0h-2.25m-1.5-6h1.5m4.25 0H24"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M24 4L4 24l5 5 5 5 5 5 5 5m0-40l20 20-5 5-5 5-5 5-5 5m0-40v40M12.75 29h1.875m5.625 0h-1.875m-1.25 5h1.25m3.542 0h-1.25M19 24h-2.917m-7.5 0H11.5m1.25-5h1.875m5.625 0h-1.875m-1.25-5h1.25m3.542 0h-1.25"
        ></path>
      </svg>
    </>
  )
}
