import React, { FC } from 'react'

export const File: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56">
      <path
        stroke="currentColor"
        strokeWidth="2"
        d="M32.667 12H19a3 3 0 00-3 3v27a2 2 0 002 2h18.333a3 3 0 003-3V18.667M32.667 12v4.667a2 2 0 002 2h4.666M32.667 12l6.666 6.667M20 20.833h11.333M20 23.5h5.667"
      ></path>
    </svg>
  )
}
