import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const AiObjectDetection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M15.5 4H7a3 3 0 00-3 3v9M39.75 4H49a3 3 0 013 3v9M4 40v9a3 3 0 003 3h9m24 0h9a3 3 0 003-3v-9"
        ></path>
        <circle cx="28" cy="28" r="11" stroke="currentColor" strokeWidth="2"></circle>
        <path stroke="currentColor" strokeWidth="2" d="M33 28a5 5 0 01-10 0"></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M13.583 4H7a3 3 0 00-3 3v7M33.792 4H41a3 3 0 013 3v7M4 34v7a3 3 0 003 3h7m20 0h7a3 3 0 003-3v-7"
        ></path>
        <circle cx="24" cy="24" r="9" stroke="currentColor" strokeWidth="2"></circle>
        <path stroke="currentColor" strokeWidth="2" d="M28.167 24a4.167 4.167 0 01-8.334 0"></path>
      </svg>
    </>
  )
}
