import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const BrightnessCorrection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M45 28c0 9.389-7.611 17-17 17s-17-7.611-17-17m34 0c0-9.389-7.611-17-17-17s-17 7.611-17 17m34 0H11M28 7V1M13.15 13.15L8.909 8.909M42.85 13.15l4.242-4.242M49 28h6M28 55v-6M47.092 47.092l-4.243-4.243M8.908 47.092l4.243-4.243M1 28h6"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M38.167 24c0 7.824-6.343 14.167-14.167 14.167S9.833 31.824 9.833 24m28.334 0c0-7.824-6.343-14.166-14.167-14.166S9.833 16.175 9.833 24m28.334 0H9.833M24 6.5v-5M11.626 11.625L8.09 8.09M36.374 11.625L39.91 8.09M41.5 24h5M24 46.5v-5M39.91 39.91l-3.536-3.535M8.09 39.91l3.536-3.535M1.5 24h5"
        ></path>
      </svg>
    </>
  )
}
