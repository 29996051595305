import React, { FC } from 'react'
import Head from 'next/head'
type PreloadFontsProps = {}

export const PreloadFonts: FC<PreloadFontsProps> = () => {
  return (
    <Head>
      <link href="/fonts/gillroy-medium.woff2" as="font" rel="preload" crossOrigin="" />
      <link href="/fonts/gillroy-medium-italic.woff2" as="font" rel="preload" crossOrigin="" />
      <link href="/fonts/gillroy-semibold.woff2" as="font" rel="preload" crossOrigin="" />
      <link href="/fonts/gillroy-semibold-italic.woff2" as="font" rel="preload" crossOrigin="" />
      <link href="/fonts/gillroy-bold.woff2" as="font" rel="preload" crossOrigin="" />
      <link href="/fonts/gillroy-bold-italic.woff2" as="font" rel="preload" crossOrigin="" />
      <link href="/fonts/gillroy-extrabold.woff2" as="font" rel="preload" crossOrigin="" />
      <link href="/fonts/gillroy-extrabold-italic.woff2" as="font" rel="preload" crossOrigin="" />
    </Head>
  )
}
