import { cx } from '@linaria/core';
import { Theme, parseAll } from '@config/theme';
import React, { FC, HTMLAttributes } from 'react';
import { ThemeSystemProps, filterProps } from 'theme-system';
const base = "bittmde";
const bodyLarge = "b1osdmp0";
const bodyLargeSpecial = "bsdx6c8";
const bodyRegular = "b1brr8g8";
export const bodySmall = "b1jgzvf2";
const micro = "mb6ewou";
const buttonLabelSmall = "bu9rr86";
const buttonLabelRegular = "b1bfc1gk";
const textSizes = {
  bodyLarge: bodyLarge,
  bodyLargeSpecial: bodyLargeSpecial,
  bodyRegular: bodyRegular,
  bodySmall: bodySmall,
  micro: micro,
  buttonLabelSmall: buttonLabelSmall,
  buttonLabelRegular: buttonLabelRegular
};
type Props = Omit<HTMLAttributes<HTMLElement>, 'color' | 'fontWeight'> & Pick<ThemeSystemProps<Theme>, 'color' | 'fontWeight' | 'm' | 'my' | 'mx' | 'mt' | 'mr' | 'mb' | 'ml'> & {
  variant: keyof typeof textSizes;
  fontWeight?: keyof typeof theme.fontWeights;
  as?: React.ElementType;
};
export const Text: FC<Props> = ({
  children,
  className,
  variant,
  as = 'div',
  ...rest
}) => {
  const Element = as;
  return (
    // @ts-ignore
    <Element className={cx(parseAll({
      ...rest
    }), textSizes[variant], base, className)} {...filterProps(rest)}>
      {children}
    </Element>
  );
};

require("./Text.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Text.tsx");