import React, { FC, AnchorHTMLAttributes, ButtonHTMLAttributes, HTMLAttributes } from 'react';
import { cx } from '@linaria/core';
import { ThemeSystemProps } from 'theme-system';
import { Theme, parseAll } from '@config/theme';
import { ArrowRight } from './icon';
import { Loader } from './icon/loader';
const styles = "sbj76s0";
const loader = "lgcwvlg";
type BaseButtonProps = {
  showIcon?: boolean;
  variant?: 'standard' | 'gradient';
  size?: 'regular' | 'large' | 'navbar';
  status?: 'idle' | 'loading';
  width?: string;
};
type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  as?: 'link';
};
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: 'button';
};
type ButtonAs = AnchorProps | ButtonProps;
type Props = HTMLAttributes<HTMLElement> & Pick<ThemeSystemProps<Theme>, 'width' | 'm' | 'my' | 'mx' | 'mt' | 'mr' | 'mb' | 'ml'> & BaseButtonProps & ButtonAs;
export const Button: FC<Props> = ({
  as,
  children,
  className,
  showIcon,
  status = 'idle',
  size = 'regular',
  variant = 'standard',
  width,
  ...rest
}) => {
  const Element: any = as === 'link' ? 'a' : 'button';
  return <Element className={cx(parseAll({
    ...rest,
    width
  }), styles, className)} data-variant={variant} data-size={size} data-status={status} {...rest}>
      {status === 'loading' && <span className={loader}>
          <Loader />
        </span>}
      {children}
      {showIcon && <ArrowRight />}
    </Element>;
};

require("./Button.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Button.tsx");