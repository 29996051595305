import { useCookies } from './page/cookies/store'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import Script from 'next/script'
export function Tracking() {
  const { preferences } = useCookies()
  useEffect(() => {
    // Tag manager
    if (preferences.includes('analytical') && process.env.NEXT_PUBLIC_STAGE === 'production') {
      TagManager.initialize({ gtmId: 'GTM-NLPHS3Q' })
    }
  }, [preferences])
  return (
    <>
      {preferences.includes('personal') && (
        <>
          <Script
            id="tag-manager"
            strategy="afterInteractive"
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`}
          />
          <Script
            id="gtag"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${process.env.NEXT_PUBLIC_GA_TRACKING_ID}');`,
            }}
          />
        </>
      )}
    </>
  )
}
