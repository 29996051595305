import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const ColorCorrection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M27.99 7l12.027 12.012c6.641 6.628 6.644 17.379.008 24.011A16.958 16.958 0 0127.989 48m0-41L15.983 19.012l-.008.008a16.897 16.897 0 00-4.854 9.973 17.056 17.056 0 00.138 4.988M27.989 7v10.25m0 30.75V37.75m0 10.25c-1.52 0-3.04-.204-4.515-.61a16.91 16.91 0 01-3.648-1.477M27.989 27.5L14.347 41.142M27.99 27.5V17.25m0 10.25v10.25m-13.642 3.392a17.131 17.131 0 005.479 4.771m-5.479-4.771a16.855 16.855 0 01-3.088-7.161m16.73-16.731L11.26 33.98m16.73 3.77l-8.163 8.163"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M24.158 7L34.18 17.01c5.534 5.524 5.537 14.482.006 20.01a14.132 14.132 0 01-10.03 4.147m0-34.167L14.154 17.01l-.007.007a14.082 14.082 0 00-4.045 8.31 14.213 14.213 0 00.115 4.157M24.158 7v8.542m0 25.625v-8.542m0 8.542c-1.267-.001-2.533-.17-3.763-.508a14.091 14.091 0 01-3.04-1.231m6.803-15.345L12.789 35.452m11.369-11.369v-8.541m0 8.541v8.542m-11.369 2.827a14.277 14.277 0 004.566 3.976m-4.566-3.976a14.053 14.053 0 01-2.573-5.968m13.942-13.942L10.216 29.484m13.942 3.141l-6.803 6.803"
        ></path>
      </svg>
    </>
  )
}
