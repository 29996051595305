import React, { FC } from 'react'

export const Folder: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M42 37.333C42 38.806 40.746 40 39.2 40H16.8c-1.546 0-2.8-1.194-2.8-2.667V18.667C14 17.194 15.254 16 16.8 16h7l2.8 4h12.6c1.546 0 2.8 1.194 2.8 2.667v14.666z"
      ></path>
    </svg>
  )
}
