import { rem } from 'polished'
import { createThemeSystem } from 'theme-system'

export const theme = {
  breakpoints: {
    sm: '768px',
    md: '1024px',
    xl: '1440px',
  },
  fontFamilies: {
    body: 'Gillroy, Helvetica, Arial, sans-serif',
  },
  fontSizes: {
    small: '1rem',
    big: '2rem',
  },
  fontWeights: {
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  space: {
    '0': 0,
    '1': '.25rem',
    '2': '.5rem',
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '13': '5rem',
    '14': '7.25rem',
    '15': '9.25rem',
    '16': '12.5rem',
    '17': '27.5rem',
    '18': '10.5rem',
    '24': rem(24),
    '40': rem(40),
    '48': rem(48),
    '56': rem(56),
    '64': rem(64),
    '80': rem(80),
    '88': rem(88),
    '104': rem(104),

    auto: 'auto',
  },
  colors: {
    blueWeb: '#1EB4FF',
    danger: '#F2453D',
    success: '#5be05c',
    gradientWeb: 'linear-gradient(93.58deg, #10C6FF 5.56%, #7636FF 97.4%)',
    gradientWebHover: 'linear-gradient(90.97deg, #12C3FF -62.18%, #7636FF 104.31%)',
    purpleWeb: '#5861FF',
    purpleWebHover: '#2E36C8',
    shade100: '#F5F6F7',
    shade200: '#EBEDF0',
    shade300: '#CDD1D9',
    shade400: '#A8AFBD',
    shade500: '#788090',
    shade600: '#454A54',
    shade700: '#1C252E',
    shade800: '#11171C',
    white: '#FFFFFF',
  },
  zIndices: {
    navBar: 5,
    modal: 10,
    cookies: 20,
    notifications: 30,
  },
  transitions: {
    css: {
      default: 'ease-in-out',
      easeInQuint: 'cubic-bezier(0.22, 1, 0.36, 1)',
    },
    motion: {
      default: [0.65, 0, 0.35, 1],
      notification: [0.34, 1.26, 0.64, 1],
    },
  },
}

export type Theme = typeof theme

export const { parse, parseAll } = createThemeSystem<Theme>(theme)
