import React, { PropsWithChildren, useEffect } from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { motion } from 'framer-motion';
import { theme } from '@config/theme';
const contentParent = "cijotvq";
const overlay = "o9ap9m7";
export const content = "c1siuzl";
export type ModalProps = {
  onRequestClose?: () => void;
};
export const Modal = ({
  children,
  onRequestClose
}: PropsWithChildren<ModalProps>) => {
  useEffect(() => {
    function handleEsc(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onRequestClose();
      }
    }
    window.addEventListener('keyup', handleEsc);
    return () => window.removeEventListener('keyup', handleEsc);
  }, []); // eslint-disable-line

  return <DialogOverlay onDismiss={onRequestClose} className={overlay}>
      <DialogContent className={contentParent}>
        <motion.div initial={{
        opacity: 0,
        y: 50
      }} animate={{
        opacity: 1,
        y: 0
      }} transition={{
        ease: theme.transitions.motion.default,
        duration: 0.2
      }} style={{
        width: '100%'
      }}>
          <div className={content}>{children}</div>
        </motion.div>
      </DialogContent>
    </DialogOverlay>;
};

require("./Modal.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Modal.tsx");