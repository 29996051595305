import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const RedEyeCorrection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M15.5 4H7a3 3 0 00-3 3v9M39.75 4H49a3 3 0 013 3v9M4 40v9a3 3 0 003 3h9m24 0h9a3 3 0 003-3v-9"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17 28s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M30.121 25.879a3 3 0 11-4.242 4.242 3 3 0 014.242-4.242z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M13.583 4H7a3 3 0 00-3 3v7M33.792 4H41a3 3 0 013 3v7M4 34v7a3 3 0 003 3h7m20 0h7a3 3 0 003-3v-7"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14.833 24s3.334-6.666 9.167-6.666S33.167 24 33.167 24 29.833 30.667 24 30.667 14.833 24 14.833 24z"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M25.768 22.232a2.5 2.5 0 11-3.536 3.536 2.5 2.5 0 013.536-3.536z"
        ></path>
      </svg>
    </>
  )
}
