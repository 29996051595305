import React, { FC } from 'react'

export const Plugin: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M28.701 14.272l10.8 5.202v0c.918.439 1.498 1.34 1.499 2.328v12.393c0 .987-.58 1.889-1.498 2.328l-10.8 5.202v0a2.792 2.792 0 01-2.417 0l-10.8-5.202h0c-.917-.445-1.492-1.352-1.485-2.34V21.801v0c0-.987.58-1.889 1.498-2.328l10.8-5.202v0a2.792 2.792 0 012.404 0v0z"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.431 20.398L27.5 26.692l13.068-6.294M27.5 41.984V26.69"
      ></path>
    </svg>
  )
}
