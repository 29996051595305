import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const Document: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M36 4H14a3 3 0 00-3 3v43a2 2 0 002 2h30a3 3 0 003-3V14M36 4v8a2 2 0 002 2h8M36 4l10 10m-29-2h8.5M17 16h8.5M17 20h23m-23 4h23m-23 4h23m-23 4h18.5M17 36h23m-23 6h23m-23 4h8.5"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M30.714 4H13a3 3 0 00-3 3v35a2 2 0 002 2h24a3 3 0 003-3V12.333M30.714 4v6.333a2 2 0 002 2H39M30.714 4L39 12.333m-24.029-1.666h7.043M14.971 14h7.043m-7.043 3.333H34.03M14.97 20.667H34.03M14.97 24H34.03M14.97 27.333H30.3m-15.329 3.334H34.03m-19.058 5H34.03M14.97 39h7.043"
        ></path>
      </svg>
    </>
  )
}
