import clientCookie from 'js-cookie'
import { FC, useEffect, useState } from 'react'
import { Modal } from './Modal'
import { Notice } from './Notice'
import { CookieOptions, useCookies } from './store'

export const Cookies: FC = () => {
  const [isMounted, setIsMounted] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const cookieStore = useCookies()

  useEffect(() => {
    if (!isMounted) {
      const cookie = clientCookie.get('cookies_settings')
      const settings = cookie ? JSON.parse(cookie).preferences : []
      cookieStore.setPreferences(settings)
    }
    setIsMounted(true)
  }, []) // eslint-disable-line

  const setCookieSettings = (settings: CookieOptions[]) => {
    cookieStore.setPreferences(settings)
  }

  return (
    <>
      {isMounted && !showSettings && cookieStore.preferences.length === 0 && (
        <Notice
          handleAgree={() => setCookieSettings(['functional', 'analytical', 'personal'])}
          handleOptions={() => setShowSettings(true)}
        />
      )}
      {showSettings && (
        <Modal
          onRequestClose={() => setShowSettings(false)}
          handleSetCookies={(settings) => setCookieSettings(settings)}
        />
      )}
    </>
  )
}
