import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const AiUpscaling: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M4 10V7a3 3 0 013-3h42a3 3 0 013 3v42a3 3 0 01-3 3v0h-3M4 22v-3a3 3 0 013-3h30a3 3 0 013 3v30a3 3 0 01-3 3h-3M16 28H7a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V31a3 3 0 00-3-3h-9z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M3.428 8.572V6.429a3 3 0 013-3h35.143a3 3 0 013 3V42A2.571 2.571 0 0142 44.572v0h-2.572m-36-25.715v-2.143a3 3 0 013-3H31.286a3 3 0 013 3V41.572a3 3 0 01-3 3h-2.143M13.714 24H6.428a3 3 0 00-3 3v14.572a3 3 0 003 3H21a3 3 0 003-3V27a3 3 0 00-3-3h-7.286z"
        ></path>
      </svg>
    </>
  )
}
