import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const PdfEnhancement: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M36 4H14a3 3 0 00-3 3v43a2 2 0 002 2h30a3 3 0 003-3V14M36 4v8a2 2 0 002 2h8M36 4l10 10M19 34v0a2 2 0 01-2-2V19a2 2 0 012-2h19a2 2 0 012 2v10m-21 5h19a2 2 0 002-2v-3m-21 5l5.793-5.793a1 1 0 011.414 0l1.086 1.086a1 1 0 001.414 0l5.086-5.086a1 1 0 011.414 0L40 29M17 39h17m-17 4h8.5"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M29.833 4H12a3 3 0 00-3 3v35a2 2 0 002 2h24.167a3 3 0 003-3V12.333M29.833 4v6.333a2 2 0 002 2h6.334M29.833 4l8.334 8.333M15.667 29v0c-.92 0-1.667-.746-1.667-1.667v-10.5a2 2 0 012-2h15.167a2 2 0 012 2v8M15.667 29h15.5a2 2 0 002-2v-2.167M15.667 29l4.71-4.71a1 1 0 011.413 0l.67.67a1 1 0 001.414 0l4.002-4.003a1 1 0 011.414 0l3.877 3.876M14 33.167h14.167M14 36.5h7.083"
        ></path>
      </svg>
    </>
  )
}
