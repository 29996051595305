import { cx } from '@linaria/core';
import React, { FC, HTMLAttributes } from 'react';
import { parseAll, Theme } from '@config/theme';
import { ThemeSystemProps, filterProps } from 'theme-system';
const base = "bfdvey";
const h1 = "h1e4e9li";
const h2 = "h14h275w";
const h3 = "h13d0pw4";
const h4 = "hkdc0l7";
const h5 = "h1dj2hdh";
const h6 = "ht1r8g3";
const variants = {
  h1: h1,
  h2: h2,
  h3: h3,
  h4: h4,
  h5: h5,
  h6: h6
};
type Props = Omit<HTMLAttributes<HTMLElement>, 'color' | 'fontWeight'> & Pick<ThemeSystemProps<Theme>, 'color' | 'fontWeight' | 'm' | 'my' | 'mx' | 'mt' | 'mr' | 'mb' | 'ml'> & {
  variant: keyof typeof variants;
  as: React.ElementType;
};
export const Heading: FC<Props> = ({
  children,
  className,
  variant,
  as,
  ...rest
}) => {
  const Element = as;
  return (
    // @ts-ignore
    <Element className={cx(parseAll({
      ...rest
    }), base, variants[variant], className)} {...filterProps(rest)}>
      {children}
    </Element>
  );
};

require("./Heading.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Heading.tsx");