import React, { FC } from 'react'

export const Server: FC = () => {
  return (
    <svg width="56" height="56" fill="none" viewBox="0 0 56 56">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 20h2.5M17 34h2.5m-3.4-9c-1.712 0-3.1-1.343-3.1-3v-3c0-1.657 1.388-3 3.1-3h24.8c1.712 0 3.1 1.343 3.1 3v3c0 1.657-1.388 3-3.1 3H16.1zm0 14c-1.712 0-3.1-1.343-3.1-3v-3c0-1.657 1.388-3 3.1-3h24.8c1.712 0 3.1 1.343 3.1 3v3c0 1.657-1.388 3-3.1 3H16.1z"
      />
    </svg>
  )
}
