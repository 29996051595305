import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const Resampling: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4 49a3 3 0 003 3h9V40H4v9z"
          clipRule="evenodd"
        ></path>
        <path fill="currentColor" d="M16 28H4v12h12V28zM16 52h12V40H16v12z"></path>
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M28 52h12m-12 0H16m12 0V40m0-36h12M28 4H16m12 0v12m24 12H40m12 0V16m0 12v12M4 28h12M4 28V16m0 12v12M40 4h9a3 3 0 013 3v9M40 4v12m0 12V16m0 12H28m12 0v12m0 12h9a3 3 0 003-3v-9M40 52V40M16 4H7a3 3 0 00-3 3v9M16 4v12m0 12h12m-12 0V16m0 12v12m0 12H7a3 3 0 01-3-3v-9m12 12V40M4 16h12m36 0H40M4 40h12m36 0H40m0-24H28m0 0v12m0-12H16m12 12v12m12 0H28m0 0H16"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4 41a3 3 0 003 3h7V34H4v7z"
          clipRule="evenodd"
        ></path>
        <path fill="currentColor" d="M14 24H4v10h10V24zM14 44h10V34H14v10z"></path>
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M24 44h10m-10 0H14m10 0V34m0-30h10M24 4H14m10 0v10m20 10H34m10 0V14m0 10v10M4 24h10M4 24V14m0 10v10M34 4h7a3 3 0 013 3v7M34 4v10m0 10V14m0 10H24m10 0v10m0 10h7a3 3 0 003-3v-7M34 44V34M14 4H7a3 3 0 00-3 3v7M14 4v10m0 10h10m-10 0V14m0 10v10m0 10H7a3 3 0 01-3-3v-7m10 10V34M4 14h10m30 0H34M4 34h10m30 0H34m0-20H24m0 0v10m0-10H14m10 10v10m10 0H24m0 0H14"
        ></path>
      </svg>
    </>
  )
}
