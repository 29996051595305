import { Cookies } from '@components/page/cookies'
import { PreloadFonts } from '@components/PreloadFonts'
import { Tracking } from '@components/Tracking'
import '@config/theme/utility-styles'
import { RewriteFrames } from '@sentry/integrations'
import * as Sentry from '@sentry/node'
import getConfig from 'next/config'
import React from 'react'
import '../styles/font-face.css'
import '../styles/global-styles.css'
import '../styles/reset.css'
import { GetInTouchContextProvider } from '@components/get-in-touch/Context'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig()
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next')
          return frame
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

export default function App({ Component, pageProps, err }) {
  return (
    <>
      <Tracking />
      <PreloadFonts />
      <GetInTouchContextProvider>
        <Component {...pageProps} err={err} />
      </GetInTouchContextProvider>
      <Cookies />
    </>
  )
}
