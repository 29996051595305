import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { theme } from '@config/theme';
import { Text } from '@components/ui/typography';
import { Button } from '@components/ui/Button';
import Link from 'next/link';
const parent = "p1rbqycy";
const container = "c1i52v25";
const buttons = "b1z0epru";
type NoticeProps = {
  handleAgree: () => void;
  handleOptions: () => void;
};
export const Notice: FC<NoticeProps> = ({
  handleAgree,
  handleOptions
}) => {
  return <motion.div initial={{
    opacity: 0,
    y: 20
  }} animate={{
    opacity: 1,
    y: 0
  }} transition={{
    ease: theme.transitions.motion.default,
    duration: 0.3,
    delay: 1
  }} className={parent}>
      <div className={container}>
        <Text variant="micro">
          We use cookies to analyze site performance and deliver personalized content. By clicking
          “Agree”, you consent to our{' '}
          <Link href="/privacy-policy.pdf">
            Privacy Policy
          </Link>
          . You may change your settings at any time.
        </Text>
        <div className={buttons}>
          <Button variant="gradient" onClick={handleAgree}>
            Agree
          </Button>
          <Button variant="standard" onClick={handleOptions}>
            More options
          </Button>
        </div>
      </div>
    </motion.div>;
};

require("./Notice.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Notice.tsx");