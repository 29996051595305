import React, { FC } from 'react'

export const Loader: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g fill="#fff" fillRule="evenodd" clipPath="url(#clip0)" clipRule="evenodd">
        <path
          fillOpacity="0.5"
          d="M8 1.865a6.135 6.135 0 100 12.27 6.135 6.135 0 000-12.27zM0 8a8 8 0 1116 0A8 8 0 010 8z"
        ></path>
        <path d="M8 0a8 8 0 018 8h-1.865A6.136 6.136 0 008 1.865V0z">
          <animateTransform
            attributeName="transform"
            dur="1s"
            from="0 8 8"
            repeatCount="indefinite"
            to="360 8 8"
            type="rotate"
          ></animateTransform>
        </path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
