import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const AiArtifactsRemoval: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path fill="currentColor" d="M28 16L16.533 37.2h22.934L28 16z"></path>
        <path
          fill="currentColor"
          d="M16.533 37.2l-.88-.476-.798 1.476h1.678v-1zm22.934 0v1h1.677l-.798-1.476-.88.476zm-18.73-20a1 1 0 000 2v-2zm.263 2a1 1 0 000-2v2zm-2.13 1.733a1 1 0 100 2v-2zm.263 2a1 1 0 100-2v2zm-2.129 1.734a1 1 0 100 2v-2zm.263 2a1 1 0 100-2v2zm-2.13 1.733a1 1 0 000 2v-2zm.263 2a1 1 0 000-2v2zm-2.13 1.733a1 1 0 100 2v-2zm.263 2a1 1 0 100-2v2zm-2.129 1.734a1 1 0 100 2v-2zm.263 2a1 1 0 100-2v2zm11.87 2.666a1 1 0 100 2v-2zm.263 2a1 1 0 000-2v2zm-4.93-2a1 1 0 100 2v-2zm.263 2a1 1 0 100-2v2zm-4.929-2a1 1 0 100 2v-2zm.263 2a1 1 0 100-2v2zM5 28V16H3v12h2zm0-12V7H3v9h2zm46-9v9h2V7h-2zm0 9v12h2V16h-2zM27 37.2V52h2V37.2h-2zM7 5h9V3H7v2zm21 0h12V3H28v2zm12 0h9V3h-9v2zM5 40v-6H3v6h2zm0-6v-6H3v6h2zm46-6v6h2v-6h-2zm0 6v6h2v-6h-2zM5 49v-3H3v3h2zm0-3v-6H3v6h2zm46-6v6h2v-6h-2zm0 6v3h2v-3h-2zm-35 5h-6v2h6v-2zm-6 0H7v2h3v-2zm12 0h-6v2h6v-2zm18 0h-6v2h6v-2zm-6 0h-6v2h6v-2zm15 0h-3v2h3v-2zm-3 0h-6v2h6v-2zM16 5h4.5V3H16v2zm4.5 0H22V3h-1.5v2zm3 46H22v2h1.5v-2zM22 5h1.5V3H22v2zm7 11V4h-2v12h2zm-1.88-.476l-11.466 21.2 1.759.952 11.467-21.2-1.76-.952zM16.533 38.2h22.934v-2H16.533v2zm23.813-1.476l-11.466-21.2-1.76.952 11.467 21.2 1.76-.952zM16.533 38.2H28v-2H16.533v2zm22.934-2H28v2h11.467v-2zM28 3h-4.5v2H28V3zm0 48h-4.5v2H28v-2zm-7.262-31.8H21v-2h-.262v2zm-1.867 3.733h.262v-2h-.262v2zm-1.867 3.734h.263v-2h-.263v2zM15.138 30.4h.262v-2h-.262v2zm-1.867 3.733h.262v-2h-.262v2zm-1.867 3.734h.263v-2h-.263v2zm12.134 4.666h.262v-2h-.262v2zm-4.667 0h.262v-2h-.262v2zm-4.667 0h.263v-2h-.263v2zM51 49a2 2 0 01-2 2v2a4 4 0 004-4h-2zm2-42a4 4 0 00-4-4v2a2 2 0 012 2h2zM5 7a2 2 0 012-2V3a4 4 0 00-4 4h2zM3 49a4 4 0 004 4v-2a2 2 0 01-2-2H3z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path fill="currentColor" d="M24 13.714L14.17 31.886h19.657L24 13.714z"></path>
        <path
          fill="currentColor"
          d="M14.171 31.886l-.88-.476-.798 1.476h1.678v-1zm19.657 0v1h1.678l-.798-1.476-.88.476zM17.775 14.6a1 1 0 100 2v-2zm.225 2a1 1 0 000-2v2zm-1.825 1.2a1 1 0 100 2v-2zm.225 2a1 1 0 000-2v2zM14.575 21a1 1 0 100 2v-2zm.225 2a1 1 0 000-2v2zm-1.825 1.2a1 1 0 100 2v-2zm.225 2a1 1 0 000-2v2zm-1.825 1.2a1 1 0 100 2v-2zm.225 2a1 1 0 000-2v2zm-1.825 1.2a1 1 0 100 2v-2zm.225 2a1 1 0 000-2v2zm10.175 2a1 1 0 100 2v-2zm.225 2a1 1 0 000-2v2zm-4.225-2a1 1 0 100 2v-2zm.225 2a1 1 0 000-2v2zm-4.225-2a1 1 0 100 2v-2zm.225 2a1 1 0 000-2v2zM4.428 24V13.714h-2V24h2zm0-10.286V6.428h-2v7.286h2zm39.143-7.286v7.286h2V6.428h-2zm0 7.286V24h2V13.714h-2zM23 31.886V44.57h2V31.886h-2zM6.428 4.428h7.286v-2H6.428v2zm17.572 0h10.286v-2H24v2zm10.286 0h7.285v-2h-7.285v2zM4.428 34.286v-5.143h-2v5.143h2zm0-5.143V24h-2v5.143h2zM43.571 24v5.143h2V24h-2zm0 5.143v5.143h2v-5.143h-2zM4.428 41.57v-2.143h-2v2.143h2zm0-2.143v-5.142h-2v5.142h2zm39.143-5.142v5.142h2v-5.142h-2zm0 5.142v2.143h2v-2.143h-2zm-29.857 4.143H8.571v2h5.143v-2zm-5.143 0H6.428v2h2.143v-2zm10.286 0h-5.143v2h5.143v-2zm15.429 0h-5.143v2h5.143v-2zm-5.143 0H24v2h5.143v-2zm12.428 0H39.43v2h2.142v-2zm-2.142 0h-5.143v2h5.142v-2zM13.713 4.428h3.857v-2h-3.857v2zm3.857 0h1.286v-2h-1.286v2zm2.572 39.143h-1.286v2h1.286v-2zM18.857 4.428h1.286v-2h-1.286v2zM25 13.714V3.428h-2v10.286h2zm-1.88-.476L13.292 31.41l1.759.951L24.88 14.19l-1.76-.952zm-8.949 19.648h19.657v-2H14.171v2zm20.537-1.476L24.88 13.238l-1.76.952L32.95 32.36l1.759-.951zm-20.537 1.476H24v-2H14.17v2zm19.657-2H24v2h9.828v-2zM24 2.428h-3.857v2H24v-2zm0 41.143h-3.857v2H24v-2zM17.775 16.6H18v-2h-.225v2zm-1.6 3.2h.225v-2h-.225v2zm-1.6 3.2h.225v-2h-.225v2zm-1.6 3.2h.225v-2h-.225v2zm-1.6 3.2h.225v-2h-.225v2zm-1.6 3.2H10v-2h-.225v2zm10.4 4h.225v-2h-.225v2zm-4 0h.225v-2h-.225v2zm-4 0h.225v-2h-.225v2zm31.396 4.971a2 2 0 01-2 2v2a4 4 0 004-4h-2zm2-35.143a4 4 0 00-4-4v2a2 2 0 012 2h2zm-41.143 0a2 2 0 012-2v-2a4 4 0 00-4 4h2zm-2 35.143a4 4 0 004 4v-2a2 2 0 01-2-2h-2z"
        ></path>
      </svg>
    </>
  )
}
