import React, { FC } from 'react';
import { Modal as UIModal } from '@components/ui/Modal';
import Box from '@components/ui/layout/Box';
import { Heading, Text } from '@components/ui/typography';
import { Button } from '@components/ui/Button';
import { CookieOptions } from './store';
import Link from 'next/link';
const content = "ca1tqi1";
const buttons = "b1ds8pzf";
type ModalProps = {
  onRequestClose: () => void;
  handleSetCookies: (settings: CookieOptions[]) => void;
};
export const Modal: FC<ModalProps> = ({
  onRequestClose,
  handleSetCookies
}) => {
  const setCookies = (settings: CookieOptions[]) => {
    handleSetCookies(settings);
    onRequestClose();
  };
  return <UIModal onRequestClose={() => {}}>
      <Box className={content}>
        <Heading variant="h5" as="h5" mb="4">
          Cookies
        </Heading>
        <Text mb="6" variant="micro">
          We use cookies to remember your login details, provide a secure log-in experience, collect
          statistics to optimize site functionality, and deliver content and advertising tailored to
          your interests. You can view detailed descriptions of the types of cookies we use in our{' '}
          <Link href="/privacy-policy.pdf">
            Privacy Policy
          </Link>
          . <br />
          <br />
          Click Agree to accept all cookies or Decline to allow only necessary cookies. You may
          change your preferences at any time by clicking the ”Cookies“ link in the footer of the
          page.
        </Text>
        <div className={buttons}>
          <Button variant="gradient" onClick={() => setCookies(['personal', 'functional', 'analytical'])} mr={{
          _: '0',
          sm: '2'
        }}>
            Agree
          </Button>
          <Button variant="standard" onClick={() => setCookies(['functional'])} mr={{
          _: '2',
          sm: '0'
        }}>
            Decline
          </Button>
        </div>
      </Box>
    </UIModal>;
};

require("./Modal.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Modal.tsx");