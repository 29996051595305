import create from 'zustand'
import clientCookie from 'js-cookie'

export type CookieOptions = 'personal' | 'analytical' | 'functional'

type CookieState = {
  preferences: CookieOptions[]
  setPreferences: (settings: CookieOptions[]) => void
}

export const useCookies = create<CookieState>((set) => ({
  preferences: [],
  setPreferences: (preferences) => {
    const settings = {
      preferences,
      acceptedAt: new Date(),
    }
    clientCookie.set('cookies_settings', JSON.stringify(settings), { expires: 365 })
    return set({ preferences: preferences })
  },
}))
