import React, { FC } from 'react'
import { hideOnSmall, showOnSmall } from './styles'

export const ContrastCorrection: FC = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        fill="none"
        viewBox="0 0 56 56"
        className={hideOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M28 48c11.046 0 20-8.954 20-20S39.046 8 28 8a19.877 19.877 0 00-10 2.676 20.091 20.091 0 00-5 4.095A19.924 19.924 0 008 28c0 5.072 1.888 9.703 5 13.23a20.091 20.091 0 005 4.094 19.874 19.874 0 005 2.046c1.598.411 3.273.63 5 .63z"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M18 10.676A19.877 19.877 0 0128 8v19h-6v2h6v19a19.874 19.874 0 01-10-2.676 20.091 20.091 0 01-5-4.095A19.924 19.924 0 018 28c0-5.072 1.888-9.703 5-13.23a20.091 20.091 0 015-4.094z"
          clipRule="evenodd"
        ></path>
        <path fill="currentColor" d="M28 27H34V29H28z"></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
        viewBox="0 0 48 48"
        className={showOnSmall}
      >
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M23.667 40.333c9.204 0 16.666-7.462 16.666-16.666C40.333 14.462 32.871 7 23.667 7a16.565 16.565 0 00-8.333 2.23 16.742 16.742 0 00-4.167 3.412A16.603 16.603 0 007 23.667c0 4.226 1.573 8.086 4.167 11.024a16.742 16.742 0 004.166 3.413 16.566 16.566 0 008.334 2.23z"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.333 9.23A16.565 16.565 0 0123.667 7v33.333a16.566 16.566 0 01-8.333-2.23 16.742 16.742 0 01-4.167-3.412A16.603 16.603 0 017 23.667c0-4.227 1.573-8.087 4.167-11.025a16.742 16.742 0 014.166-3.412zm8.334 13.603h-5V24.5h5v-1.667z"
          clipRule="evenodd"
        ></path>
        <path fill="currentColor" d="M23.667 22.834H28.667V24.501H23.667z"></path>
      </svg>
    </>
  )
}
