import React, { FC } from 'react'

export const Mobile: FC = () => {
  return (
    <svg width="56" height="56" fill="none" viewBox="0 0 56 56">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="M25.476 17.177h4.048M21 41h13a2 2 0 002-2V16a2 2 0 00-2-2H21a2 2 0 00-2 2v23a2 2 0 002 2z"
      />
    </svg>
  )
}
